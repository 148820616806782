import React from "react";
import { useParams } from "react-router-dom";

const Word = () => {
  const { name, category } = useParams(); // modeId
  return (
    <div className="flex flex-col items-center justify-center h-screen  text-right">
      <div className="bg-background p-2 rounded-lg text-white min-w-80 min-h-80 relative font-bold flex flex-col items-center justify-center">
        <div className="flex justify-center items-center h-1/5 text-2xl">
          <h1>{name}</h1>
        </div>
        <div className="flex justify-center items-center h-1/5 bg-secondaryButtonShadow rounded-md w-2/3 text-start">
          <h1>فئة: {category}</h1>
        </div>
      </div>
    </div>
  );
};

export default Word;
